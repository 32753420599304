import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const HyperTT = styled.h1`
  text-align: center;
  font-size: 3rem;
  font-family: "Latin Modern Roman";
  font-style: italic;
  color: orchid;
  }
`

const TagPage = () => (
  <Layout>
    <HyperTT>
      t a g s
    </HyperTT>
  </Layout>
)

export default TagPage

// export default ({ data }) => {
//   const post = data.markdownRemark
//   return (
//     <Layout>
//       {/* <SEO title={post.frontmatter.title} /> */}
//       {/* <HyperCT>{post.frontmatter.title}</HyperCT> */}
//       <HyperTT>Posts w/ tag "${tag}"</HyperTT>
//       {/* <Turner /> */}
//     </Layout>
//   )
// }

// export const query = graphql`
//   query($tag: String) {
//     allMarkdownRemark(
//       filter: { frontmatter: { tags: { in: [$tag] } } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             title
//           }
//         }
//       }
//     }
//   }
// `